import React, { useState, useEffect } from 'react';
import {Container} from "@material-ui/core";
import { useHistory } from "react-router-dom";
import DataTable from "../../helpers/DataTable";
import ActivityTypeAdd from "./ActivityTypeAdd";
import ActivityTypeEdit from "./ActivityTypeEdit";
import ActivityStateList from "../activityState/ActivityStateList";
import Button from "@material-ui/core/Button";

import Request from "../../helpers/Request";
import Alert from "../../helpers/Alert";


const ActivityTypeList = (params) => {
    const history = useHistory();

    const [operationType, setOperationType] = useState("");
    const [selectedID, setSelectedID] = useState(0);
    const [activityTypeList, setActivityTypeList] = useState([]);

    const columns = [
        {field:"activity_type_id", filtering: false, render: rowData => <Button variant="contained" color="primary" id="activity_type_id" onClick={()=>activityStateButton(rowData)}>Aktivite Durumları</Button>},
        {title: "ID", field: "activity_type_id", type: "numeric"},
        {title: "Aktivite Tipi", field: "name"},
    ];

    useEffect(() =>  {
        getList();
    },[params]);

    const activityStateButton = (rowData) => {
        console.log(rowData.activity_type_id);
        setOperationType("activityState");
        history.push("/activityState",{activity_type_id: rowData.activity_type_id});
    };

    const getList = async () => {
        const response = await Request.get("activityType/all");

        if(response.status === "error") {
            Alert("error", response.message);
            return false
        }
        setActivityTypeList(response.data);
    };

    const add = () => {
        setOperationType('add')
    };
    const edit = (data) => {
        setOperationType('edit');
        setSelectedID(data.activity_type_id);
    };
    const remove = async (data) => {
        const response = await Request.delete("activityType/delete", data.activity_type_id);
        if(response.status === "error"){
            Alert("error", response.message);
            return false
        }
    };

    return <Container>
        {operationType === 'add' &&
        <ActivityTypeAdd operationType={operationType} selectedID={selectedID} getList={ () => {getList()}}
                     close={()=>{setOperationType(""); setSelectedID(0);}} />
        }
        {operationType === 'edit' &&
        <ActivityTypeEdit operationType={operationType} selectedID={selectedID} getList={() => {getList()}}
                          close={() => {setOperationType("");setSelectedID(0);}}/>
        }
        {
            operationType === '' &&
            <DataTable columns={columns} data={activityTypeList} title="Aktivite Tipi Listesi"
                       add={() => add()} edit={data => edit(data)} remove={data => remove(data)} />
        }


    </Container>
};

export default ActivityTypeList;

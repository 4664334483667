import React, { useState, useEffect } from 'react';
import {Container} from "@material-ui/core";
import {useLocation} from "react-router-dom";
import { useHistory } from "react-router-dom";
import DataTable from "../../helpers/DataTable";
import Request from "../../helpers/Request";
import NeighborhoodAdd from "./NeighborhoodAdd";
import NeighborhoodEdit from "./NeighborhoodEdit";
import Button from "@material-ui/core/Button";
import Alert from "../../helpers/Alert";

const NeighborhoodList = (params) => {
    const location = useLocation();
    const history = useHistory();

    const [operationType, setOperationType] = useState("");
    const [countryID, setCountryID] = useState(0);
    const [cityID, setCityID] = useState(0);
    const [districtID, setDistrictID] = useState(0);
    const [localityID, setLocalityID] = useState(0);
    const [neighborhoodID, setNeighborhoodID] = useState(0);

    const [districtList, setDistrictList] = useState([]);

    const columns = [
        {title: "ID", field: "neighborhood_id", type: "numeric"},
        {title: "Ülke Adı", field: "country"},
        {title: "Şehir Adı", field: "city"},
        {title: "İlçe Adı", field: "district"},
        {title: "Semt Adı", field: "locality"},
        {title: "Mahalle Adı", field: "name"}
    ];
    useEffect(() =>  {
        getList();
    },[params]);


    const getList = async () => {
        const response = await Request.post("neighborhood/all",{locality_id: location.state.locality_id});

        if(response.status === "error") {
            Alert("error", response.message);
            return false
        }
        setDistrictList(response.data);
    };


    const add = () => {
        setOperationType("add");
        setLocalityID(location.state.locality_id);
    };
    const edit = (data) => {
        setOperationType('edit');
        setCountryID(location.state.country_id);
        setCityID(location.state.city_id);
        setDistrictID(location.state.district_id);
        setLocalityID(location.state.locality_id);
        setNeighborhoodID(data.neighborhood_id);
    };
    const remove = async (data) => {
        const response = await Request.delete("neighborhood/delete", data.neighborhood_id);
        if(response.status === "error"){
            Alert("error", response.message);
            getList();
            return false;
        }
        getList();
    };

    return <Container>
        {operationType === 'add' &&
        <NeighborhoodAdd operationType={operationType} localityID={localityID} getList={ () => {getList()}}
                     close={()=>{setOperationType(""); setNeighborhoodID(0);}} />
        }
        {operationType === 'edit' &&
        <NeighborhoodEdit operationType={operationType} countryID={countryID} cityID={cityID} districtID={districtID} localityID={localityID} neighborhoodID={neighborhoodID}
                      getList={ () => {getList()}} close={()=>{setOperationType(""); setNeighborhoodID(0);}} />
        }
        {
            operationType === '' &&
            <DataTable columns={columns} data={districtList} title="Mahalle Listesi"
                       add={() => add()} edit={data => edit(data)} remove={data => remove(data)} />
        }
    </Container>
};

export default NeighborhoodList;

import React, {useEffect, useState} from 'react';
import Container from "@material-ui/core/Container";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import CancelPresentationTwoToneIcon from '@material-ui/icons/CancelPresentationTwoTone';
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField/TextField";
import Box from "@material-ui/core/Box";
import Validate from "../../helpers/Validation";
import Request from "../../helpers/Request";
import Alert from "../../helpers/Alert";
import InputLabel from '@material-ui/core/InputLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';


const SourceSubAdd = (props) => {
    const initialVariables = {
        source_id: "", customer_id: "0", name: ""
    };

    const [values, setValues] = useState(initialVariables);
    const [error, setErrors] = useState(initialVariables);
    const [customerList, setCustomerList] = useState([]);

    const initialErrorStates = Object.keys(initialVariables).map(key => ({key: false}));
    const [errorShow, setErrorShow] = useState(initialErrorStates);

    useEffect(() => {
        if(Object.values(errorShow).filter(val => (val)).length === 0){
            console.log("Form Can Be Send Now!");
        }
    }, [errorShow]);

    useEffect( () => {
        List();
    },[setCustomerList]);

    const List = async () => {
        const response = await Request.get("customer/all");

        if(response.status === "error") {
            Alert("error", response.message);
            return false
        }
        setCustomerList(response.data);
    };

    const handleFormChange = (key, val) => {
        let currentState = values;
        currentState[key] = val;
        setValues({...currentState});
    };
    const sendForm = async (e) => {
        console.log(values);
        const validation = Validate({
            "name": "general",
        }, values);
        if(validation.errorCount > 0){
            setErrors(validation.errorList);
            setErrorShow(validation.errorShowList);
            return false;
        }
        const response = await Request.post("sourceSub/add",{source_id: props.sourceID, customer_id: values.customer_id, name: values.name});

        if(response.status === "error"){
            Alert("error", response.message);
            return false
        }
        Alert("success", "Müşteri Alt Kaynağı Eklendi.");
        props.close();
        props.getList();
    };
    return <Container >
        <Grid container justify="flex-end">
            <Button onClick={() => props.close()}><CancelPresentationTwoToneIcon /></Button>
        </Grid>
        <Grid container item justify="center">
            <Typography variant="h5">Müşteri Alt Kaynağı Ekle</Typography>
        </Grid>
        <Grid container item justify="center">
            <div style={{width: "400px"}}>
                <Box m={1}>
                    <FormControl variant="outlined" fullWidth>
                        <InputLabel htmlFor="outlined-age-native-simple">Firma</InputLabel>
                        <Select fullWidth native value={values.customer_id}
                                onChange={e => handleFormChange("customer_id", e.target.value)} helperText={error.customer_id} error={errorShow.customer_id}
                                label="Firma Seçin" inputProps={{name: 'customer_id', id: 'outlined-age-native-simple',}}>
                            <option value={0}>Hepsi</option>
                            {
                                customerList.map((item, index) => {
                                    return <option value={item.customer_id} key={index}>{item.name}</option>;
                                })
                            }
                        </Select>
                        <FormHelperText error={errorShow.customer_id}>{error.customer_id}</FormHelperText>
                    </FormControl>
                </Box>
                <Box m={1}>
                    <TextField fullWidth autoComplete="off" label="Müşteri Alt Kaynağı Adı" variant="outlined"
                               value={values.name} helperText={error.name} error={errorShow.name}
                               onChange={e => handleFormChange("name", e.target.value)}/>
                </Box>
            </div>
        </Grid>
        <Grid container item justify="center">
            <Button variant="contained" color="primary" style={{marginTop: "2vh"}} onClick={()=>sendForm()}>Kaydet</Button>
        </Grid>
    </Container>
};






export default SourceSubAdd;

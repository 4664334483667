import {toast} from 'react-toastify';

const Messages = {
    "token": "Oturum Süreniz Zaman Aşımına Uğradı",
    "auth_type": "Yetki Tipiniz Sistemde Kayıtlı Değil",
    "staff_not_found": "Yönetici Sistemde Kayıtlı Değil",
    "password_changed": "Şifreniz Başarıyla Değiştirilmiştir",
    "ok": "İşlem Başarıyla Tamamlanmıştır",
    "validation_error": "Lütfen Bilgileri Kontrol Ediniz",
    "email_error": "Lütfen E-Postanızı Kontrol Ediniz",
    "pass_error": "Lütfen Şifrenizi Kontrol Ediniz"
};

const Alert = (type, text) => {

    const staticMessages = Object.keys(Messages).filter(message => message === text);
    text = (staticMessages.length > 0)?Messages[text]:text;

    switch (type) {
        case "success":
            toast.success(text, {position: "top-center"});
            break;
        case "error":
            toast.error(text, {position: "top-center"});
            break;
        default:
            toast.info(text, {position: "top-center"});
            break;
    }
};

export default Alert;
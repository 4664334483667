import React, { useState, useEffect } from 'react';
import {Container} from "@material-ui/core";

import Alert from "../../helpers/Alert";
import DataTable from "../../helpers/DataTable";
import StaffAdd from "./StaffAdd";
import StaffEdit from "./StaffEdit";
import Request from "../../helpers/Request";

const StaffList = (params) => {

    const [operationType, setOperationType] = useState("");
    const [selectedID, setSelectedID] = useState(0);
    const [staffList, setStaffList] = useState([]);

    const columns = [
        {title: "ID", field: "staff_id", type: "numeric"},
        {title: "İsim", field: "name"},
        {title: "Kullanıcı Adı", field: "username"}

    ];
    useEffect(() =>  {
        getList();
    },[params]);

    const getList = async () => {
        const response = await Request.get("staff/all");

        if(response.status === "error") {
            Alert("error", response.data);
            return false
        }
        setStaffList(response.data);
    };


    const add = () => { setOperationType("add") };
    const edit = (data) => {
        setOperationType('edit');
        setSelectedID(data.staff_id);};
    const remove = async (data) => {
        const response = await Request.delete("staff/delete", data.staff_id);
        if(response.status === "error"){
            Alert("error", response.data);
            return false
        }
    };

    return <Container>
            {operationType === 'add' &&
                <StaffAdd operationType={operationType} selectedID={selectedID} getList={ () => {getList()}}
                          close={()=>{setOperationType(""); setSelectedID(0);}} />
            }
            {operationType === 'edit' &&
            <StaffEdit operationType={operationType} selectedID={selectedID} getList={ () => {getList()}}
                      close={()=>{setOperationType(""); setSelectedID(0);}} />
            }
            {
                operationType === '' &&
                <DataTable columns={columns} data={staffList} title="Personel Listesi"
                           add={() => add()} edit={data => edit(data)} remove={data => remove(data)} />
            }


    </Container>
};

export default StaffList;

import React, { useState, useEffect } from 'react';
import {Container} from "@material-ui/core";
import { useHistory } from "react-router-dom";
import DataTable from "../../helpers/DataTable";
import Button from "@material-ui/core/Button";


import Request from "../../helpers/Request";
import TitleAdd from "./TitleAdd";
import TitleEdit from "./TitleEdit";
import Alert from "../../helpers/Alert";


const TitleList = (params) => {

    const [operationType, setOperationType] = useState("");
    const [titleID, setTitleID] = useState(0);
    const [titleList, setTitleList] = useState([]);

    const columns = [
        {title: "ID", field: "title_id", type: "numeric"},
        {title: "Ünvan", field: "name"},
    ];

    useEffect(() =>  {
        getList();
    },[params]);


    const getList = async () => {
        const response = await Request.get("title/all");

        if(response.status === "error") {
            Alert("error", response.message);
            return false
        }
        setTitleList(response.data);
    };

    const add = () => {
        setOperationType('add')
    };
    const edit = (data) => {
        setOperationType('edit');
        setTitleID(data.title_id);
    };
    const remove = async (data) => {
        const response = await Request.delete("title/delete", data.title_id);
        if(response.status === "error"){
            Alert("error", response.message);
            return false
        }
    };

    return <Container>
        {operationType === 'add' &&
        <TitleAdd operationType={operationType} titleID={titleID} getList={ () => {getList()}}
                   close={()=>{setOperationType(""); setTitleID(0);}} />
        }
        {operationType === 'edit' &&
        <TitleEdit operationType={operationType} titleID={titleID} getList={() => {getList()}}
                    close={() => {setOperationType("");setTitleID(0);}}/>
        }
        {
            operationType === '' &&
            <DataTable columns={columns} data={titleList} title="Ünvan Listesi"
                       add={() => add()} edit={data => edit(data)} remove={data => remove(data)} />
        }


    </Container>
};

export default TitleList;

import React, {useEffect, useState} from 'react';
import Container from "@material-ui/core/Container";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import CancelPresentationTwoToneIcon from '@material-ui/icons/CancelPresentationTwoTone';
import Typography from "@material-ui/core/Typography";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import TextField from "@material-ui/core/TextField/TextField";
import Box from "@material-ui/core/Box";
import Select from "@material-ui/core/Select";
import FormHelperText from "@material-ui/core/FormHelperText";
import Validate from "../../helpers/Validation";
import Request from "../../helpers/Request";
import Alert from "../../helpers/Alert";


const StaffEdit = (props) => {
    const initialVariables = {
        name: "", username: "", pass: "", email: "", auth: "", phone: ""
    };

    const [values, setValues] = useState(initialVariables);
    const [error, setErrors] = useState(initialVariables);

    const initialErrorStates = Object.keys(initialVariables).map(key => ({key: false}));
    const [errorShow, setErrorShow] = useState(initialErrorStates);

    useEffect(() => {
        if(Object.values(errorShow).filter(val => (val)).length === 0){
            console.log("Form Can Be Send Now!");
        }
    }, [errorShow]);

    useEffect(() => {
        ( async () => {
            const response = await Request.post("staff/one",{staff_id: props.selectedID});
            if(response.status === "error") {
                Alert("error", response.message);
                return false
            }
            setValues(response.data);
        })();

    },[props.selectedID]);

    const handleFormChange = (key, val) => {
        let currentState = values;
        currentState[key] = val;
        setValues({...currentState});
    };
    const sendForm = async (e) => {
        const validation = Validate({
            "name": "text",
            "username": "text",
            "pass": "pass",
            "email": "email",
            "auth": "text",
            "phone": "phone"
        }, values);
        if(validation.errorCount > 0){
            setErrors(validation.errorList);
            setErrorShow(validation.errorShowList);
            return false;
        }
        const response = await Request.post("staff/update",values);

        if(response.status === "error"){
            Alert("error", response.message);
            return false
        }
        Alert("success", "Kullanıcı Güncellendi.");
        props.close();
        props.getList();
    };
    return <Container >
        <Grid container justify="flex-end">
            <Button onClick={() => props.close()}><CancelPresentationTwoToneIcon /></Button>
        </Grid>
        <Grid container item justify="center">
            <Typography variant="h5">Çalışan Güncelle</Typography>
        </Grid>
        <Grid container item justify="center">
            <div style={{width: "400px"}}>
                <Box m={1}>
                    <TextField fullWidth autoComplete="off" label="İsim" variant="outlined"
                               value={values.name} helperText={error.name} error={errorShow.name}
                               onChange={e => handleFormChange("name", e.target.value)}/>
                </Box>
                <Box m={1}>
                    <TextField fullWidth autoComplete="off" label="Kullanıcı Adı" variant="outlined"
                               value={values.username} helperText={error.username} error={errorShow.username}
                               onChange={e => handleFormChange("username", e.target.value)}/>
                </Box>
                <Box m={1}>
                    <TextField fullWidth autoComplete="off" label="Şifre" variant="outlined"
                               value={values.pass} helperText={error.pass} error={errorShow.pass}
                               onChange={e => handleFormChange("pass", e.target.value)}/>
                </Box>
                <Box m={1}>
                    <TextField fullWidth autoComplete="off" label="E-Posta" variant="outlined"
                               value={values.email} helperText={error.email} error={errorShow.email}
                               onChange={e => handleFormChange("email", e.target.value)}/>
                </Box>
                <Box m={1}>
                    <FormControl variant="outlined" fullWidth>
                        <InputLabel htmlFor="outlined-age-native-simple">Yetki</InputLabel>
                        <Select fullWidth native value={values.auth}
                                onChange={e => handleFormChange("auth", e.target.value)} helperText={error.auth} error={errorShow.auth}
                                label="Yetki" inputProps={{name: 'auth', id: 'outlined-age-native-simple',}}>
                            <option aria-label="Yok" value="" />
                            <option value={"admin"}>Yönetici</option>
                            <option value={"staff"}>Personel</option>
                        </Select>
                        <FormHelperText error={errorShow.auth}>{error.auth}</FormHelperText>
                    </FormControl>
                </Box>
                <Box m={1}>
                    <TextField fullWidth autoComplete="off" label="Telefon" variant="outlined"
                               value={values.phone} helperText={error.phone} error={errorShow.phone}
                               onChange={e => handleFormChange("phone", e.target.value)}/>
                </Box>
            </div>
        </Grid>
        <Grid container item justify="center">
            <Button variant="contained" color="primary" style={{marginTop: "2vh"}} onClick={()=>sendForm()}>Güncelle</Button>
        </Grid>
    </Container>
};






export default StaffEdit;

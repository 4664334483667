import React, { useState, useEffect } from 'react';
import {Container} from "@material-ui/core";
import { useHistory } from "react-router-dom";
import DataTable from "../../helpers/DataTable";
import Button from "@material-ui/core/Button";
import JobAdd from "./JobAdd";
import JobEdit from "./JobEdit";

import Request from "../../helpers/Request";
import Alert from "../../helpers/Alert";



const JobList = (params) => {

    const [operationType, setOperationType] = useState("");
    const [jobID, setJobID] = useState(0);
    const [jobList, setJobList] = useState([]);

    const columns = [
        {title: "ID", field: "job_id", type: "numeric"},
        {title: "Meslek İsmi", field: "name"},
    ];

    useEffect(() =>  {
        getList();
    },[params]);


    const getList = async () => {
        const response = await Request.get("job/all");

        if(response.status === "error") {
            Alert("error", response.message);
            return false
        }
        setJobList(response.data);
    };

    const add = () => {
        setOperationType('add')
    };
    const edit = (data) => {
        setOperationType('edit');
        setJobID(data.job_id);
    };
    const remove = async (data) => {
        const response = await Request.delete("job/delete", data.job_id);
        if(response.status === "error"){
            Alert("error", response.message);
            return false
        }
    };

    return <Container>
        {operationType === 'add' &&
        <JobAdd operationType={operationType} jobID={jobID} getList={ () => {getList()}}
                   close={()=>{setOperationType(""); setJobID(0);}} />
        }
        {operationType === 'edit' &&
        <JobEdit operationType={operationType} jobID={jobID} getList={() => {getList()}}
                    close={() => {setOperationType("");setJobID(0);}}/>
        }
        {
            operationType === '' &&
            <DataTable columns={columns} data={jobList} title="Meslek Listesi"
                       add={() => add()} edit={data => edit(data)} remove={data => remove(data)} />
        }


    </Container>
};

export default JobList;

import React, { useState, useEffect } from 'react';
import {Container} from "@material-ui/core";
import {useLocation} from "react-router-dom";

import DataTable from "../../helpers/DataTable";
import UserAdd from "./UserAdd";
import UserEdit from "./UserEdit";
import Request from "../../helpers/Request";
import Alert from "../../helpers/Alert";


const UserList = (params) => {
    const location = useLocation();

    const [operationType, setOperationType] = useState("");
    const [customerID, setCustomerID] = useState(0);
    const [userID, setUserID] = useState(0);
    const [userList, setUserList] = useState([]);

    const columns = [
        {title: "ID", field: "user_id", type: "numeric"},
        {title: "Firma Adı", field: "customer"},
        {title: "Adı", field: "name"},
        {title: "Kullanıcı Adı", field: "username"}
    ];
    useEffect(() =>  {
        getList();
    },[params]);

    const getList = async () => {
        const response = await Request.post("user/getAllByCustomerID",{customer_id: location.state.customer_id});

        console.log(response.data);
        if(response.status === "error") {
            Alert("error", response.message);
            return false
        }
        setUserList(response.data);
    };


    const add = () => {
        setOperationType("add");
        setCustomerID(location.state.customer_id);
    };
    const edit = (data) => {
        setOperationType('edit');
        setUserID(data.user_id);
        setCustomerID(location.state.customer_id);
    };
    const remove = async (data) => {
        const response = await Request.delete("user/delete", data.user_id);
        if(response.status === "error"){
            Alert("error", response.message);
            return false
        }
    };

    return <Container>
        {operationType === 'add' &&
        <UserAdd operationType={operationType} userID={userID} customerID={customerID} getList={ () => {getList()}}
                     close={()=>{setOperationType(""); setUserID(0);}} />
        }
        {operationType === 'edit' &&
        <UserEdit operationType={operationType} userID={userID} customerID={customerID} getList={ () => {getList()}}
                 close={()=>{setOperationType(""); setUserID(0);}} />
        }
        {
            operationType === '' &&
            <DataTable columns={columns} data={userList} title="Kullanıcı Listesi"
                       add={() => add()} edit={data => edit(data)} remove={data => remove(data)} />
        }


    </Container>
};

export default UserList;

import MenuItem from "@material-ui/core/MenuItem";
import IconButton from "@material-ui/core/IconButton";
import {AccountBox, AddToPhotos, LocationOn, SupervisorAccount, Work, AssignmentInd, ContactMail, Person} from "@material-ui/icons";
import MenuList from "@material-ui/core/MenuList";
import React from "react";
import { useHistory } from "react-router-dom";


const Menu = () => {
    const history = useHistory();
    return <MenuList>
        <MenuItem>
            <IconButton onClick={() => history.push("/customer")} >
                <AccountBox/>&nbsp;Firma
            </IconButton>
        </MenuItem>
        <MenuItem>
            <IconButton onClick={() => history.push("/source")} >
                <AssignmentInd/>&nbsp;Müşteri Kaynağı
            </IconButton>
        </MenuItem>
        <MenuItem>
            <IconButton onClick={() => history.push("activityType")} >
                <AddToPhotos/>&nbsp;Aktivite
            </IconButton>
        </MenuItem>
        <MenuItem>
            <IconButton onClick={() => history.push("/reason")} >
                <ContactMail/>&nbsp;Hizmet Alma Nedeni
            </IconButton>
        </MenuItem>
        <MenuItem>
            <IconButton onClick={() => history.push("/country")} >
                <LocationOn/>&nbsp;Lokasyon
            </IconButton>
        </MenuItem>
        <MenuItem>
            <IconButton onClick={() => history.push("/job")} >
                <Work/>&nbsp;Meslek
            </IconButton>
        </MenuItem>
        <MenuItem>
            <IconButton onClick={() => history.push("/title")} >
                <Person/>&nbsp;Ünvan
            </IconButton>
        </MenuItem>
        <MenuItem>
            <IconButton onClick={() => history.push("/staff")} >
                <SupervisorAccount/>&nbsp;Personel
            </IconButton>
        </MenuItem>

    </MenuList>
}

export default Menu;

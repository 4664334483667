import React, { useState, useEffect } from 'react';
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import {Typography} from "@material-ui/core";
import Validate from "../../helpers/Validation";
import Request from "../../helpers/Request";
import Session from "../../helpers/Session";

import { useHistory } from "react-router-dom";
import Container from "@material-ui/core/Container";
import Alert from "../../helpers/Alert";

const StaffLogin = (props) => {
    const history = useHistory();
    const initialVariables = {
        username: "", pass: ""
    };

    const [values, setValues] = useState(initialVariables);
    const [error, setErrors] = useState(initialVariables);

    const initialErrorStates = Object.keys(initialVariables).map(key => ({key: false}));
    const [errorShow, setErrorShow] = useState(initialErrorStates);

    useEffect(() => {
        if(Object.values(errorShow).filter(val => (val)).length === 0){
            console.log("Form Can Be Send Now!");
        }
    }, [errorShow]);

    const handleFormChange = (key, val) => {
        let currentState = values;
        currentState[key] = val;
        setValues({...currentState});
    };

    const sendForm = async (e) => {
        const validation = Validate({
            "username": "text",
            "pass": "pass",
        }, values);
        if(validation.errorCount > 0){
            setErrors(validation.errorList);
            setErrorShow(validation.errorShowList);
            return false;
        }
        const response = await Request.post("staff/login",{username: values.username, pass: values.pass});
        console.log(response);
        if(response.status === "error"){
            Alert("error", response.message);
            return false;
        }
        props.setAuth("staff");
        Session.set("token", response.data.token);
    };

    return <Container>
        <Grid container alignItems="center" className="mt-7">
            <Grid container item justify="center" xs={12} md={4}>
                <Typography variant="h5">
                    MBusiness V3
                </Typography>
            </Grid>
            <Grid container item direction="column" justify="center" xs={12} md={8}>
                <Box m={1}>
                    <TextField fullWidth autoComplete="off" label="Kullanıcı Adı" variant="outlined"
                               value={values.username} helperText={error.username} error={errorShow.username}
                               onChange={e => handleFormChange("username", e.target.value)}/>
                </Box>
                <Box m={1}>
                    <TextField fullWidth autoComplete="off" label="Şifre" variant="outlined" type="password"
                               value={values.pass} helperText={error.pass} error={errorShow.pass}
                               onChange={e => handleFormChange("pass", e.target.value)} />
                </Box>
                <Box m={1}>
                    <Button fullWidth variant="contained" size="large" color="primary" onClick={()=>sendForm()}>Giriş yap</Button>
                </Box>
            </Grid>
        </Grid>
    </Container>
};

export default StaffLogin;

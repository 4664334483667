import React, {useEffect, useState} from 'react';
import ReactDOM from 'react-dom';
import {ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './index.css';
import './theme.css';
import * as serviceWorker from './serviceWorker';
import {Router, Route} from "react-router-dom";
import {createBrowserHistory} from "history";

import StaffLogin from "./modules/staff/StaffLogin";
import StaffList from "./modules/staff/StaffList";
import UserList from "./modules/user/UserList";
import CustomerList from "./modules/customer/CustomerList";
import ActivityTypeList from "./modules/activityType/ActivityTypeList";
import ActivityStateList from "./modules/activityState/ActivityStateList";
import ActivityResultList from "./modules/activityResult/ActivityResultList";
import CountryList from "./modules/country/CountryList";
import CityList from "./modules/city/CityList";
import DistrictList from "./modules/district/DistrictList";
import LocalityList from "./modules/locality/LocalityList";
import NeighborhoodList from "./modules/neighborhood/NeighborhoodList";
import SourceList from "./modules/source/SourceList";
import SourceSubList from "./modules/sourceSub/sourceSubList";
import JobList from "./modules/job/JobList";
import ReasonList from "./modules/reason/ReasonList";
import CssBaseline from "@material-ui/core/CssBaseline";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Container from "@material-ui/core/Container";
import Box from "@material-ui/core/Box";
import {AccountBox, AddToPhotos, ExitToApp, LocationOn, SupervisorAccount} from "@material-ui/icons";

import CustomTheme from './theme';
import {ThemeProvider} from "@material-ui/styles";
import Grid from "@material-ui/core/Grid";
import MenuList from "@material-ui/core/MenuList";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "./modules/Menu";
import TitleList from "./modules/title/TitleList";
import Session from "./helpers/Session";



const App = (props) => {
    const initialAuth = (Session.get("token"))?"staff":"guest";
    const [auth, setAuth] = useState(initialAuth);
    const history = createBrowserHistory(props);
    const {window} = props;
    const [mobileOpen, setMobileOpen] = React.useState(false);


    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    const logout = () => {
        setAuth("guest");
        Session.remove("token");
    };


    const container = window !== undefined ? () => window().document.body : undefined;

    if(auth === "guest" )
        return <ThemeProvider theme={CustomTheme}>
                    <StaffLogin setAuth={(newAuth) => setAuth(newAuth)}  />
                    <ToastContainer/>
                </ThemeProvider>;

    return (<Router history={history}>
            <ThemeProvider theme={CustomTheme}>
                <CssBaseline/>
                {auth === "staff" &&
                <>
                    <AppBar position="fixed">
                        <Toolbar>
                            <Box display="flex" justifyContent="flex-end" className="w-100">
                                <IconButton onClick={() => logout()}>
                                    <ExitToApp/>
                                    ÇIKIŞ
                                </IconButton>
                            </Box>
                        </Toolbar>
                    </AppBar>
                    <Grid container className="mt-6">
                        <Grid item xs={12} md={2}>
                            <Menu />
                        </Grid>
                        <Grid item xs={12} md={10}>
                            <Container>
                                <Route path="/staff" exact component={StaffList} />
                                <Route path="/user" exact component={UserList} />
                                <Route path="/customer" exact component={CustomerList} />
                                <Route path="/activityType" exact component={ActivityTypeList} />
                                <Route path="/activityState" exact component={ActivityStateList} />
                                <Route path="/activityResult" exact component={ActivityResultList} />
                                <Route path="/country" exact component={CountryList} />
                                <Route path="/city" exact component={CityList} />
                                <Route path="/district" exact component={DistrictList} />
                                <Route path="/locality" exact component={LocalityList} />
                                <Route path="/neighborhood" exact component={NeighborhoodList} />
                                <Route path="/source" exact component={SourceList} />
                                <Route path="/sourceSub" exact component={SourceSubList} />
                                <Route path="/job" exact component={JobList} />
                                <Route path="/reason" exact component={ReasonList} />
                                <Route path="/title" exact component={TitleList} />
                            </Container>
                            <ToastContainer/>
                        </Grid>
                    </Grid>

                </>
                }
            </ThemeProvider>
        </Router>
    );
};

ReactDOM.render(
    <App />,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
/*<main>
            <Route exact path="/" component={DataContainer}/>
            <Route path="/data" component={DataContainer}/>
        </main> */

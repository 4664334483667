import React, {useEffect, useState} from 'react';
import Container from "@material-ui/core/Container";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import CancelPresentationTwoToneIcon from '@material-ui/icons/CancelPresentationTwoTone';
import Typography from "@material-ui/core/Typography";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import TextField from "@material-ui/core/TextField/TextField";
import Box from "@material-ui/core/Box";
import Select from "@material-ui/core/Select";
import FormHelperText from "@material-ui/core/FormHelperText";
import Validate from "../../helpers/Validation";
import Request from "../../helpers/Request";
import Alert from "../../helpers/Alert";


const CustomerEdit = (props) => {
    const initialVariables = {
        name: "", customer_code: "", service_type: "", exchange_rate: "", central_ip: "",
        trigger_path: "", db_ip: "", db_name: "", db_user: "", db_pass: ""
    };

    const [values, setValues] = useState(initialVariables);
    const [error, setErrors] = useState(initialVariables);

    const initialErrorStates = Object.keys(initialVariables).map(key => ({key: false}));
    const [errorShow, setErrorShow] = useState(initialErrorStates);

    useEffect(() => {
        if(Object.values(errorShow).filter(val => (val)).length === 0){
            console.log("Form Can Be Send Now!");
        }
    }, [errorShow]);

    useEffect(() => {
        ( async () => {
            const response = await Request.post("customer/one",{customer_id: props.selectedID});
            if(response.status === "error") {
                Alert("error", response.message);
                return false
            }
            setValues(response.data);
        })();

    },[props.selectedID]);

    const handleFormChange = (key, val) => {
        let currentState = values;
        currentState[key] = val;
        setValues({...currentState});
    };
    const sendForm = async (e) => {
        const validation = Validate({
            "name": "general",
            "customer_code": "clean_text",
            "service_type": "text",
            "exchange_rate": "text"
        }, values);
        if(validation.errorCount > 0){
            setErrors(validation.errorList);
            setErrorShow(validation.errorShowList);
            return false;
        }
        const response = await Request.post("customer/update",values);

        if(response.status === "error"){
            Alert("error", response.message);
            return false
        }
        Alert("success", "Firma Güncellendi.");
        props.close();
        props.getList();
    };
    return <Container >
        <Grid container justify="flex-end">
            <Button onClick={() => props.close()}><CancelPresentationTwoToneIcon /></Button>
        </Grid>
        <Grid container item justify="center">
            <Typography variant="h5">Firma Güncelle</Typography>
        </Grid>
        <Grid container item justify="center">
            <div style={{width: "400px"}}>
                <Box m={1}>
                    <TextField fullWidth autoComplete="off" label="Firma İsim" variant="outlined"
                               value={values.name} helperText={error.name} error={errorShow.name}
                               onChange={e => handleFormChange("name", e.target.value)}/>
                </Box>
                <Box m={1}>
                    <TextField fullWidth autoComplete="off" label="Firma Kodu" variant="outlined"
                               value={values.customer_code} helperText={error.customer_code} error={errorShow.customer_code}
                               onChange={e => handleFormChange("customer_code", e.target.value)}/>
                </Box>
                <Box m={1}>
                    <FormControl variant="outlined" fullWidth>
                        <InputLabel htmlFor="outlined-age-native-simple">Servis Tipi</InputLabel>
                        <Select fullWidth native value={values.service_type}
                                onChange={e => handleFormChange("service_type", e.target.value)} helperText={error.service_type} error={errorShow.service_type}
                                label="Servis Tipi" inputProps={{name: 'service_type', id: 'outlined-age-native-simple',}}>
                            <option aria-label="Seçiniz" value="Seçiniz" />
                            <option value={"all"}>Hepsi</option>
                            <option value={"crm"}>CRM</option>
                            <option value={"central"}>Santral</option>
                            <option value={"bulk"}>Toplu Gönderim</option>
                            <option value={"intranet"}>Intranet</option>
                        </Select>
                        <FormHelperText error={errorShow.service_type}>{error.service_type}</FormHelperText>
                    </FormControl>
                </Box>
                <Box m={1}>
                    <FormControl variant="outlined" fullWidth>
                        <InputLabel htmlFor="outlined-age-native-simple">Para Birimi</InputLabel>
                        <Select fullWidth native value={values.exchange_rate}
                                onChange={e => handleFormChange("exchange_rate", e.target.value)} helperText={error.exchange_rate} error={errorShow.exchange_rate}
                                label="Para Birimi" inputProps={{name: 'exchange_rate', id: 'outlined-age-native-simple',}}>
                            <option aria-label="Seçiniz" value="Seçiniz" />
                            <option value={"tl"}>TL</option>
                            <option value={"eur"}>EUR</option>
                            <option value={"usd"}>Dolar</option>
                            <option value={"gbp"}>Sterlin</option>
                        </Select>
                        <FormHelperText error={errorShow.exchange_rate}>{error.exchange_rate}</FormHelperText>
                    </FormControl>
                </Box>
                <Box m={1}>
                    <TextField fullWidth autoComplete="off" label="Santral Ip" variant="outlined"
                               value={values.central_ip} helperText={error.central_ip} error={errorShow.central_ip}
                               onChange={e => handleFormChange("central_ip", e.target.value)}/>
                </Box>
                <Box m={1}>
                    <TextField fullWidth autoComplete="off" label="Santral Dosyasının Konumu" variant="outlined"
                               value={values.trigger_path} helperText={error.trigger_path} error={errorShow.trigger_path}
                               onChange={e => handleFormChange("trigger_path", e.target.value)}/>
                </Box>
                <Box m={1}>
                    <TextField fullWidth autoComplete="off" label="Database Ip" variant="outlined"
                               value={values.db_ip} helperText={error.db_ip} error={errorShow.db_ip}
                               onChange={e => handleFormChange("db_ip", e.target.value)}/>
                </Box>
                <Box m={1}>
                    <TextField fullWidth autoComplete="off" label="Database Adı" variant="outlined"
                               value={values.db_name} helperText={error.db_name} error={errorShow.db_name}
                               onChange={e => handleFormChange("db_name", e.target.value)}/>
                </Box>
                <Box m={1}>
                    <TextField fullWidth autoComplete="off" label="Database Kullanıcı Adı" variant="outlined"
                               value={values.db_user} helperText={error.db_user} error={errorShow.db_user}
                               onChange={e => handleFormChange("db_user", e.target.value)}/>
                </Box>
                <Box m={1}>
                    <TextField fullWidth autoComplete="off" label="Database Şifresi" variant="outlined"
                               value={values.db_pass} helperText={error.db_pass} error={errorShow.db_pass}
                               onChange={e => handleFormChange("db_pass", e.target.value)}/>
                </Box>
            </div>
        </Grid>
        <Grid container item justify="center">
            <Button variant="contained" color="primary" style={{marginTop: "2vh"}} onClick={()=>sendForm()}>Güncelle</Button>
        </Grid>
    </Container>
};






export default CustomerEdit;

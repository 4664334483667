import React, { useState, useEffect } from 'react';
import {Container} from "@material-ui/core";

import DataTable from "../../helpers/DataTable";
import ActivityStateAdd from "./ActivityStateAdd";
import ActivityStateEdit from "./ActivityStateEdit";
import Request from "../../helpers/Request";
import {useLocation} from "react-router-dom";
import { useHistory } from "react-router-dom";
import Button from "@material-ui/core/Button";
import Alert from "../../helpers/Alert";

const ActivityStateList = (params) => {
    const location = useLocation();
    const history = useHistory();
    const [operationType, setOperationType] = useState("");
    const [activityTypeID, setActivityTypeID] = useState(0);
    const [activityStateID, setActivityStateID] = useState(0);
    const [activityTypeList, setActivityTypeList] = useState([]);

    const columns = [
        {field:"activity_state_id", filtering: false, render: rowData => <Button variant="contained" color="primary" onClick={()=>activityResultButton(rowData)}>Aktivite Sonuçları</Button>},
        {title: "ID", field: "activity_state_id", type: "numeric"},
        {title: "Aktivite Tipi", field: "type"},
        {title: "Aktivite Durumu", field: "name"}
    ];
    useEffect(() =>  {
        getList();
    },[params]);

    const getList = async () => {
        const response = await Request.post("activityState/allByTypeID",{activity_type_id: location.state.activity_type_id});

        if(response.status === "error") {
            Alert("error", response.message);
            return false
        }
        setActivityTypeList(response.data);
    };

    const activityResultButton = (rowData) => {
        console.log(rowData.activity_type_id);
        setOperationType("activityState");
        history.push("/activityResult",{activity_type_id: location.state.activity_type_id, activity_state_id: rowData.activity_state_id});
    };

    const add = () => {
        setOperationType("add");
        setActivityTypeID(location.state.activity_type_id);
    };
    const edit = (data) => {
        setOperationType('edit');
        setActivityTypeID(location.state.activity_type_id);
        setActivityStateID(data.activity_state_id);
    };
    const remove = async (data) => {
        const response = await Request.delete("activityState/delete", data.activity_state_id);
        if(response.status === "error"){
            Alert("error", response.message);
            return false
        }
    };

    return <Container>
        {operationType === 'add' &&
        <ActivityStateAdd operationType={operationType} activityTypeID={activityTypeID}  getList={ () => {getList()}}
                         close={()=>{setOperationType("");}} />
        }
        {operationType === 'edit' &&
        <ActivityStateEdit operationType={operationType} activityStateID={activityStateID} activityTypeID={activityTypeID} getList={ () => {getList()}}
                          close={()=>{setOperationType(""); setActivityStateID(0);}} />
        }
        {
            operationType === '' &&
            <DataTable columns={columns} data={activityTypeList} title="Aktivite Durum Listesi"
                       add={() => add()} edit={data => edit(data)} remove={data => remove(data)} />
        }


    </Container>
};

export default ActivityStateList;

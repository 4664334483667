import React, { useState, useEffect } from 'react';
import {Container} from "@material-ui/core";
import { useHistory } from "react-router-dom";
import DataTable from "../../helpers/DataTable";
import CountryAdd from "./CountryAdd";
import CountryEdit from "./CountryEdit";
import Button from "@material-ui/core/Button";

import Request from "../../helpers/Request";
import Alert from "../../helpers/Alert";


const CountryList = (params) => {
    const history = useHistory();

    const [operationType, setOperationType] = useState("");
    const [selectedID, setSelectedID] = useState(0);
    const [countryList, setCountryList] = useState([]);

    const columns = [
        {field:"country_id", filtering: false, render: rowData => <Button variant="contained" color="primary" onClick={()=>cityButton(rowData)}>Şehir Listesi</Button>},
        {title: "ID", field: "country_id", type: "numeric"},
        {title: "Ülke Adı", field: "name"}
    ];

    useEffect(() =>  {
        getList();
    },[params]);

    const cityButton = (rowData) => {
        console.log(rowData.country_id);
        history.push("/city",{country_id: rowData.country_id});
    };

    const getList = async () => {
        const response = await Request.get("country/all");

        if(response.status === "error") {
            Alert("error", response.message);
            return false
        }
        setCountryList(response.data);
    };

    const add = () => {
        setOperationType('add')
    };
    const edit = (data) => {
        setOperationType('edit');
        setSelectedID(data.country_id);
    };
    const remove = async (data) => {
        const response = await Request.delete("country/delete", data.country_id);
        if(response.status === "error"){
            Alert("error", response.message);
            return false
        }
    };

    return <Container>
        {operationType === 'add' &&
        <CountryAdd operationType={operationType} selectedID={selectedID} getList={ () => {getList()}}
                         close={()=>{setOperationType(""); setSelectedID(0);}} />
        }
        {operationType === 'edit' &&
        <CountryEdit operationType={operationType} selectedID={selectedID} getList={() => {getList()}}
                          close={() => {setOperationType("");setSelectedID(0);}}/>
        }
        {
            operationType === '' &&
            <DataTable columns={columns} data={countryList} title="Ülke Listesi"
                       add={() => add()} edit={data => edit(data)} remove={data => remove(data)} />
        }


    </Container>
};

export default CountryList;

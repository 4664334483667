import React, { useState, useEffect } from 'react';
import {Container} from "@material-ui/core";

import DataTable from "../../helpers/DataTable";
import Request from "../../helpers/Request";
import {useLocation} from "react-router-dom";
import SourceSubAdd from "./sourceSubAdd";
import SourceSubEdit from "./sourceSubEdit";
import Alert from "../../helpers/Alert";


const SourceSubList = (params) => {
    const location = useLocation();
    const [operationType, setOperationType] = useState("");
    const [sourceID, setSourceID] = useState(0);
    const [sourceSubID, setSourceSubID] = useState(0);
    const [sourceSubList, setSourceSubList] = useState([]);

    const columns = [
        {title: "ID", field: "source_sub_id", type: "numeric"},
        {title: "Müşteri Kaynağı", field: "source"},
        {title: "Müşteri Alt Kaynak Adı", field: "name"}
    ];
    useEffect(() =>  {
        getList();
    },[params]);

    const getList = async () => {
        const response = await Request.post("sourceSub/all",{source_id: location.state.source_id});

        if(response.status === "error") {
            Alert("error", response.message);
            return false
        }
        setSourceSubList(response.data);
    };

    const add = () => {
        setOperationType("add");
        setSourceID(location.state.source_id);
    };
    const edit = (data) => {
        setOperationType('edit');
        setSourceID(location.state.source_id);
        setSourceSubID(data.source_sub_id);
    };
    const remove = async (data) => {
        const response = await Request.delete("sourceSub/delete", data.source_sub_id);
        if(response.status === "error"){
            Alert("error", response.message);
            return false
        }
    };

    return <Container>
        {operationType === 'add' &&
        <SourceSubAdd operationType={operationType} sourceID={sourceID}  getList={ () => {getList()}}
                 close={()=>{setOperationType("");}} />
        }
        {operationType === 'edit' &&
        <SourceSubEdit operationType={operationType} sourceSubID={sourceSubID} sourceID={sourceID} getList={ () => {getList()}}
                  close={()=>{setOperationType(""); setSourceSubID(0);}} />
        }
        {
            operationType === '' &&
            <DataTable columns={columns} data={sourceSubList} title="Müşteri Alt Kaynağı Listesi"
                       add={() => add()} edit={data => edit(data)} remove={data => remove(data)} />
        }

    </Container>
};

export default SourceSubList;

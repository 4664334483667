import React, { useState, useEffect } from 'react';
import {Container} from "@material-ui/core";
import {useLocation} from "react-router-dom";
import { useHistory } from "react-router-dom";
import DataTable from "../../helpers/DataTable";
import DistrictAdd from "./DistrictAdd";
import Request from "../../helpers/Request";
import Button from "@material-ui/core/Button";
import DistrictEdit from "./DistrictEdit";
import Alert from "../../helpers/Alert";

const DistrictList = (params) => {
    const location = useLocation();
    const history = useHistory();

    const [operationType, setOperationType] = useState("");
    const [countryID, setCountryID] = useState(0);
    const [cityID, setCityID] = useState(0);
    const [districtID, setDistrictID] = useState(0);

    const [districtList, setDistrictList] = useState([]);

    const columns = [
        {field:"district_id", filtering: false, render: rowData => <Button variant="contained" color="primary" onClick={()=>localityButton(rowData)}>Semtler</Button>},
        {title: "ID", field: "district_id", type: "numeric"},
        {title: "Ülke Adı", field: "country"},
        {title: "Şehir Adı", field: "city"},
        {title: "İlçe Adı", field: "name"},

    ];
    useEffect(() =>  {
        getList();
    },[params]);

    const localityButton = (rowData) => {
        history.push("/locality",{country_id: location.state.country_id, city_id: location.state.city_id, district_id: rowData.district_id});
    };

    const getList = async () => {
        const response = await Request.post("district/all",{city_id: location.state.city_id});

        if(response.status === "error") {
            Alert("error", response.message);
            return false
        }
        setDistrictList(response.data);
    };


    const add = () => {
        setOperationType("add");
        setCityID(location.state.city_id);
    };
    const edit = (data) => {
        setOperationType('edit');
        setCountryID(location.state.country_id);
        setCityID(location.state.city_id);
        setDistrictID(data.district_id);
    };
    const remove = async (data) => {
        const response = await Request.delete("district/delete", data.district_id);
        if(response.status === "error"){
            Alert("error", response.message);
            getList();
            return false;
        }
        getList();
    };

    return <Container>
        {operationType === 'add' &&
        <DistrictAdd operationType={operationType} cityID={cityID} getList={ () => {getList()}}
                           close={()=>{setOperationType(""); setDistrictID(0);}} />
        }
        {operationType === 'edit' &&
        <DistrictEdit operationType={operationType} countryID={countryID} cityID={cityID} districtID={districtID}
                            getList={ () => {getList()}} close={()=>{setOperationType(""); setDistrictID(0);}} />
        }
        {
            operationType === '' &&
            <DataTable columns={columns} data={districtList} title="İlçe Listesi"
                       add={() => add()} edit={data => edit(data)} remove={data => remove(data)} />
        }
    </Container>
};

export default DistrictList;

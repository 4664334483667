import React, { useState, useEffect } from 'react';
import {Container} from "@material-ui/core";
import { useHistory } from "react-router-dom";

import DataTable from "../../helpers/DataTable";
import CustomerAdd from "./CustomerAdd";
import CustomerEdit from "./CustomerEdit";
import Request from "../../helpers/Request";
import Button from "@material-ui/core/Button";
import Alert from "../../helpers/Alert";

const CustomerList = (params) => {
    const history = useHistory();

    const [operationType, setOperationType] = useState("");
    const [selectedID, setSelectedID] = useState(0);
    const [customerList, setCustomerList] = useState([]);

    const columns = [
        {title: "", field:"country_id", filtering: false, render: rowData => <Button variant="contained" color="primary" onClick={()=>userButton(rowData)}>Kullanıcı Listesi</Button>},
        {title: "ID", field: "customer_id", type: "numeric"},
        {title: "Firma Adı", field: "name"},
        {title: "Firma Kodu", field: "customer_code"},

    ];
    useEffect(() =>  {
        getList();
    },[params]);

    const getList = async () => {
        const response = await Request.get("customer/all");

        if(response.status === "error") {
            Alert("error", response.message);
            return false
        }
        setCustomerList(response.data);
    };

    const userButton = (rowData) => {
        history.push("/user",{customer_id: rowData.customer_id});
    };


    const add = () => { setOperationType("add") };
    const edit = (data) => {
        setOperationType('edit');
        setSelectedID(data.customer_id);
    };
    const remove = async (data) => {
        const response = await Request.delete("customer/delete", data.customer_id);
        if(response.status === "error"){
            Alert("error", response.message);
            return false
        }
    };

    return <Container>
        {operationType === 'add' &&
        <CustomerAdd operationType={operationType} selectedID={selectedID} getList={ () => {getList()}}
                  close={()=>{setOperationType(""); setSelectedID(0);}} />
        }
        {operationType === 'edit' &&
        <CustomerEdit operationType={operationType} selectedID={selectedID} getList={ () => {getList()}}
                     close={()=>{setOperationType(""); setSelectedID(0);}} />
        }
        {
            operationType === '' &&
            <DataTable columns={columns} data={customerList} title="Firma Listesi"
                       add={() => add()} edit={data => edit(data)} remove={data => remove(data)} />
        }


    </Container>
};

export default CustomerList;

import {createMuiTheme} from "@material-ui/core/styles";

const CustomTheme = createMuiTheme({
    palette: {
        primary: {
            main: '#02acac',
            contrastText: '#FFFFFF'
        },
        secondary: {
            light: '#ffcc00',
            main: '#ffcc00',
            // dark: will be calculated from palette.secondary.main,
            contrastText: '#000000',
        },
        contrastThreshold: 3,
        tonalOffset: 0.2,
    },
});

export default CustomTheme;

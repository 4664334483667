import React, { useState, useEffect } from 'react';
import {Container} from "@material-ui/core";

import DataTable from "../../helpers/DataTable";
import Request from "../../helpers/Request";
import {useLocation} from "react-router-dom";
import { useHistory } from "react-router-dom";
import CityAdd from "./CityAdd";
import CityEdit from "./CityEdit";
import Button from "@material-ui/core/Button";
import Alert from "../../helpers/Alert";


const CityList = (params) => {
    const location = useLocation();
    const history = useHistory();
    const [operationType, setOperationType] = useState("");
    const [countryID, setCountryID] = useState(0);
    const [cityID, setCityID] = useState(0);
    const [cityList, setCityList] = useState([]);

    const columns = [
        {field:"city_id", filtering: false, render: rowData => <Button variant="contained" color="primary" onClick={()=>districtButton(rowData)}>İlçe</Button>},
        {title: "ID", field: "city_id", type: "numeric"},
        {title: "Ülke Adı", field: "country"},
        {title: "Şehir Adı", field: "name"},
    ];
    useEffect(() =>  {
        getList();
    },[params]);

    const getList = async () => {
        const response = await Request.post("city/all",{country_id: location.state.country_id});

        if(response.status === "error") {
            Alert("error", response.message);
            return false
        }
        setCityList(response.data);
    };

    const districtButton = (rowData) => {
        history.push("/district",{country_id: location.state.country_id, city_id: rowData.city_id});
    };

    const add = () => {
        setOperationType("add");
        setCountryID(location.state.country_id);
    };
    const edit = (data) => {
        setOperationType('edit');
        setCountryID(location.state.country_id);
        setCityID(data.city_id);
    };
    const remove = async (data) => {
        const response = await Request.delete("city/delete", data.city_id);
        if(response.status === "error"){
            Alert("error", response.message);
            return false
        }
    };

    return <Container>
        {operationType === 'add' &&
        <CityAdd operationType={operationType} countryID={countryID}  getList={ () => {getList()}}
                          close={()=>{setOperationType("");}} />
        }
        {operationType === 'edit' &&
        <CityEdit operationType={operationType} cityID={cityID} countryID={countryID} getList={ () => {getList()}}
                           close={()=>{setOperationType(""); setCityID(0);}} />
        }
        {
            operationType === '' &&
            <DataTable columns={columns} data={cityList} title="Şehir Listesi"
                       add={() => add()} edit={data => edit(data)} remove={data => remove(data)} />
        }

    </Container>
};

export default CityList;

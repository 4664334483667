const Validate = (params, values) => {
    const errorList = {};
    const errorShowList = {};
    let errorCount = 0;
    Object.keys(params).map((key) => {
        const err = Validation(params[key], values[key]);
        if(err.length > 0){
            errorCount++;
            errorList[key] = err;
            errorShowList[key] = true;
        }
        return false;
    });
    return {errorCount, errorList, errorShowList};
}


const Validation = (type, param, param2) => {
    switch(type){
        case "general":
            if (param === "") return "Bu Alanı Boş Bırakmayınız";
            else if (!!String(param).match(/[^ '"/\\\s\r\n\-+(),.?%*:0-9A-z$₺€İĞŞÖÇÜığüşçö]/g)) return "Uygunsuz Karakter Tespit Edildi";
            else return "";
        case "text":
            if (param === "") return "Bu Alanı Boş Bırakmayınız";
            else if (String(param).length < 2) return "Bu Alan En Az 2 Karakterden Oluşmalıdır";
            else if (!!String(param).match(/[^ A-zİĞŞÖÇÜığüşçö]/g)) return "Bu Alan Yalnızca Harflerden Oluşmalıdır";
            else return "";
        case "number":
            if (param === "") return "Bu Alanı Boş Bırakmayınız";
            else if (!!String(param).match(/[^0-9]/g)) return "Bu Alan Yalnızca Rakamlardan Oluşmalıdır";
            else return "";
        case "email":
            if (param === "") return "Bu Alanı Boş Bırakmayınız";
            else if (param.length < 5) return "Bu Alan En Az 5 Karakterden Oluşmalıdır";
            else if (String(param).match(/^(\s*,?\s*[a-z0-9_-]+[a-z0-9_.-]+@[a-z0-9_-]+[a-z0-9_.]*\.[a-z,]{2,5})+\s*$/) == null) return "E-Posta uygun formatta değil";
            else return "";
        case "pass":
            if (param === "") return "Şifre Boş Bırakmayınız";
            else if (String(param).length < 8) return "Şifre En Az 8 Karakterden Oluşmalıdır";
            else if (!!String(param).match(/[A-ZİĞŞÖÇÜ]/g) === false) return "Şifre En Az Bir Büyük Harf İçermelidir";
            else if (!!String(param).match(/[a-zığüşçö]/g) === false) return "Şifre En Az Bir Küçük Harf İçermelidir";
            else if (!!String(param).match(/[0-9]/g) === false) return "Şifre En Az Bir Rakam İçermelidir";
            else if (!!String(param).match(/[!'^+%&*".,?-_:;]/g) === false) return "Şifre En Az Bir Özel Karakter İçermelidir";
            else return "";
        case "phone":
            if (param === "") return "Bu Alanı Boş Bırakmayınız";
            else if (param.length < 10){
                const paramParts = param.split("");
                if(paramParts[0] === "4" && paramParts[1] === "4" && paramParts[2] === "4")
                    return "";
                else
                    return "Telefon En Az 10 Karakterden Oluşmalıdır";
            }
            else if (!!String(param).match(/[^0-9]/g)) return "Telefon Sadece Rakamlardan Oluşmalıdır";
            else return "";
        case "price":
            if (!!String(param).match(/[^0-9.]/g)) return "Sadece Rakam ve '.' girebilirsiniz";
            else return "";
        case "passConfirm":
            if (param !== param2) return "Şifreler Uyuşmuyor";
            else return "";
        case "clean_text":
            if (param === "") return "Bu Alanı Boş Bırakmayınız";
            else if (!!String(param).match(/[^ '"/\\\s\r\n\-+(),.?%*:0-9A-z$₺€İĞŞÖÇÜığüşçö]/g)) return "Uygunsuz Karakter Tespit Edildi";
            else return "";
        default:
            return "Validate edilecek element tipi belirtilmelidir";
    }

};

export default Validate;

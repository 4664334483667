import React, { useState, useEffect } from 'react';
import {Container} from "@material-ui/core";
import {useLocation} from "react-router-dom";
import { useHistory } from "react-router-dom";
import DataTable from "../../helpers/DataTable";
import Request from "../../helpers/Request";
import Button from "@material-ui/core/Button";
import LocalityAdd from "./LocalityAdd";
import LocalityEdit from "./LocalityEdit";
import Alert from "../../helpers/Alert";

const LocalityList = (params) => {
    const location = useLocation();
    const history = useHistory();

    const [operationType, setOperationType] = useState("");
    const [countryID, setCountryID] = useState(0);
    const [cityID, setCityID] = useState(0);
    const [districtID, setDistrictID] = useState(0);
    const [localityID, setLocalityID] = useState(0);

    const [districtList, setDistrictList] = useState([]);

    const columns = [
        {title: "Mahalle", field:"locality_id", filtering: false, render: rowData => <Button variant="contained" color="primary" onClick={()=>neighborhoodButton(rowData)}>Mahalle</Button>},
        {title: "ID", field: "locality_id", type: "numeric"},
        {title: "Ülke Adı", field: "country"},
        {title: "Şehir Adı", field: "city"},
        {title: "İlçe Adı", field: "district"},
        {title: "Semt Adı", field: "name"},
    ];
    useEffect(() =>  {
        getList();
    },[params]);

    const neighborhoodButton = (rowData) => {
        history.push("/neighborhood",{country_id: location.state.country_id, city_id: location.state.city_id, district_id: location.state.district_id, locality_id: rowData.locality_id});
    };

    const getList = async () => {
        const response = await Request.post("locality/all",{district_id: location.state.district_id});

        if(response.status === "error") {
            Alert("error", response.message);
            return false
        }
        setDistrictList(response.data);
    };


    const add = () => {
        setOperationType("add");
        setDistrictID(location.state.district_id);
    };
    const edit = (data) => {
        setOperationType('edit');
        setCountryID(location.state.country_id);
        setCityID(location.state.city_id);
        setDistrictID(location.state.district_id);
        setLocalityID(data.locality_id);
    };
    const remove = async (data) => {
        const response = await Request.delete("locality/delete", data.locality_id);
        if(response.status === "error"){
            Alert("error", response.message);
            getList();
            return false;
        }
        getList();
    };

    return <Container>
        {operationType === 'add' &&
        <LocalityAdd operationType={operationType} districtID={districtID} getList={ () => {getList()}}
                     close={()=>{setOperationType(""); setLocalityID(0);}} />
        }
        {operationType === 'edit' &&
        <LocalityEdit operationType={operationType} countryID={countryID} cityID={cityID} districtID={districtID} localityID={localityID}
                      getList={ () => {getList()}} close={()=>{setOperationType(""); setLocalityID(0);}} />
        }
        {
            operationType === '' &&
            <DataTable columns={columns} data={districtList} title="Semt Listesi"
                       add={() => add()} edit={data => edit(data)} remove={data => remove(data)} />
        }
    </Container>
};

export default LocalityList;

import React, {useEffect, useState} from 'react';
import Container from "@material-ui/core/Container";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import CancelPresentationTwoToneIcon from '@material-ui/icons/CancelPresentationTwoTone';
import Typography from "@material-ui/core/Typography";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import TextField from "@material-ui/core/TextField/TextField";
import Box from "@material-ui/core/Box";
import Select from "@material-ui/core/Select";
import FormHelperText from "@material-ui/core/FormHelperText";
import Validate from "../../helpers/Validation";
import Request from "../../helpers/Request";
import Alert from "../../helpers/Alert";


const LocalityAdd = (props) => {
    const initialVariables = {
        locality_id: "", district_id: "", name: ""
    };

    const [values, setValues] = useState(initialVariables);
    const [error, setErrors] = useState(initialVariables);

    const initialErrorStates = Object.keys(initialVariables).map(key => ({key: false}));
    const [errorShow, setErrorShow] = useState(initialErrorStates);

    useEffect(() => {
        if(Object.values(errorShow).filter(val => (val)).length === 0){
            console.log("Form Can Be Send Now!");
        }
    }, [errorShow]);


    const handleSelectChange = (key, val) => {
        let currentState = values;
        currentState[key] = val;
        setValues({...currentState});
    };


    const sendForm = async (e) => {
        const validation = Validate({
            "name": "general",
        }, values);
        if(validation.errorCount > 0){
            setErrors(validation.errorList);
            setErrorShow(validation.errorShowList);
            return false;
        }
        const response = await Request.post("locality/add",{district_id: props.districtID, name: values.name});

        if(response.status === "error"){
            Alert("error", response.message);
            return false
        }
        Alert("success", "Semt Eklendi.");
        props.close();
        props.getList();
    };
    return <Container >
        <Grid container justify="flex-end">
            <Button onClick={() => props.close()}><CancelPresentationTwoToneIcon /></Button>
        </Grid>
        <Grid container item justify="center">
            <Typography variant="h5">Semt Ekle</Typography>
        </Grid>
        <Grid container item justify="center">
            <div style={{width: "400px"}}>
                <Box m={1}>
                    <FormControl variant="outlined" fullWidth>
                        <TextField fullWidth autoComplete="off" label="Semt Adı" variant="outlined"
                                   value={values.name}  helpers={errorShow.name}
                                   onChange={e => handleSelectChange("name", e.target.value)}/>
                        <FormHelperText error={true}>{error.name}</FormHelperText>
                    </FormControl>
                </Box>
            </div>
        </Grid>
        <Grid container item justify="center">
            <Button variant="contained" color="primary" style={{marginTop: "2vh"}} onClick={()=>sendForm()}>Kaydet</Button>
        </Grid>
    </Container>
};


export default LocalityAdd;

import React, { useState, useEffect } from 'react';
import {Container} from "@material-ui/core";
import {useLocation} from "react-router-dom";
import DataTable from "../../helpers/DataTable";
import ActivityResultAdd from "./ActivityResultAdd";
import ActivityResultEdit from "./ActivityResultEdit";
import Request from "../../helpers/Request";

import Alert from '../../helpers/Alert';

const ActivityResultList = (params) => {
    const location = useLocation();

    const [operationType, setOperationType] = useState("");
    const [activityTypeID, setActivityTypeID] = useState(0);
    const [activityStateID, setActivityStateID] = useState(0);
    const [activityResultID, setActivityResultID] = useState(0);

    const [activityResultList, setActivityResultList] = useState([]);

    const columns = [
        {title: "ID", field: "activity_result_id", type: "numeric"},
        {title: "Aktivite Tipi", field: "activityType"},
        {title: "Aktivite Durumu", field: "activityState"},
        {title: "Aktivite Sonuc", field: "name"},
    ];
    useEffect(() =>  {
        getList();
    },[params]);

    const getList = async () => {
        const response = await Request.post("activityResult/allByStateID",{activity_state_id: location.state.activity_state_id});

        if(response.status === "error") {
            Alert("error", response.message);
            return false
        }
        setActivityResultList(response.data);
    };


    const add = () => {
        setOperationType("add");
        setActivityStateID(location.state.activity_state_id);
    };
    const edit = (data) => {
        setOperationType('edit');
        setActivityTypeID(location.state.activity_type_id);
        setActivityStateID(location.state.activity_state_id);
        setActivityResultID(data.activity_result_id);
    };
    const remove = async (data) => {
        const response = await Request.delete("activityResult/delete", data.activity_result_id);
        if(response.status === "error"){
            Alert("error", response.message);
            getList();
            return false;
        }
        getList();
    };

    return <Container>
        {operationType === 'add' &&
        <ActivityResultAdd operationType={operationType} activityStateID={activityStateID} getList={ () => {getList()}}
                          close={()=>{setOperationType(""); setActivityResultID(0);}} />
        }
        {operationType === 'edit' &&
        <ActivityResultEdit operationType={operationType} activityTypeID={activityTypeID} activityStateID={activityStateID} activityResultID={activityResultID}
                            getList={ () => {getList()}} close={()=>{setOperationType(""); setActivityResultID(0);}} />
        }
        {
            operationType === '' &&
            <DataTable columns={columns} data={activityResultList} title="Aktivite Sonuc Listesi"
                       add={() => add()} edit={data => edit(data)} remove={data => remove(data)} />
        }
    </Container>
};

export default ActivityResultList;

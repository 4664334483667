//import store from '../storage';
import Session from "./Session";
const URL = 'http://melantis.net/core/';//'http://localhost/mbusiness_v3/mbusiness_core/';//
const Request = {};

Request.login = async (url, body, returnType) => {
    try {
        const req = await fetch(URL + url, {
            method: "POST",
            headers: {"Content-Type": "application/json"},
            body: JSON.stringify(body)
        });
        //console.log(typeof req, req);
        if(req.status !== 200){
            return {status: "error", data: req.message};
        }
        if (returnType) {
            const response = await req.text();
            return {status: "success", data: response};
        }
        const response = await req.json();
        //console.log(typeof response, response);
        return response;
    } catch (e) {
        console.log(e);
    }
};

Request.get = async (url) => {
    console.log("Request Get Called", url);
    const token = Session.get("token");
    try {
        const req = await fetch(URL + url, {
            headers: {"Authorization": "Bearer "+ token }

        });
        if(req.status !== 200){
            return {status: "error", data: req.message};
        }
        const response = await req.json();
        return response;
    } catch (e) {
        return e
    }
};

Request.post = async (url, body, returnType) => {
    //console.log("Request Post Called", url);
    //const state = store.getState();
    //const token = state.token;
    const token = Session.get("token");
    try {
        const req = await fetch(URL + url, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + token
            },
            body: JSON.stringify(body)
        });

        if(req.status !== 200){
            const response = await req.json();
            return {status: "error", data: response.message};
        }
        if (returnType) {
            const response =  await req.text();
            return {status: "success", data: response};
        }
        const response = await req.json();
        return response;
    } catch (e) {
        console.log(e);
    }
};

Request.postWithoutToken = async (url, body, returnType) => {
    try {
        const req = await fetch(URL + url, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(body)
        });
        if(req.status !== 200){
            const response = await req.json();
            return {status: "error", data: response.message};
        }
        if (returnType) {
            const response =  await req.text();
            return {status: "success", data: response};
        }
        const response = await req.json();
        return response;
    } catch (e) {
        console.log(e);
    }
};

Request.put = async (url, id, body, returnType) => {
    //console.log("Request Post Called", url);
    //const state = store.getState();
    //const token = state.token;
    try {
        const req = await fetch(URL + url + "/" + id, {
            method: "PUT",
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer "
            },
            body: JSON.stringify(body)
        });

        if(req.status !== 200){
            const response = await req.json();
            return {status: "error", data: response.message};
        }
        if (returnType) {
            const response =  await req.text();
            return {status: "success", data: response};
        }
        const response = await req.json();
        return response;
    } catch (e) {
        console.log(e);
    }
};

Request.delete = async (url, id) => {
    //const state = store.getState();
    //const token = state.token;
    const token = Session.get("token");
    try {
        const req = await fetch(URL + url + "/" + id, {
            method: "DELETE",
            headers: {"Authorization": "Bearer "+ token}
        });
        return await req.json();
    } catch (e) {
        return e
    }
};

Request.upload = async (url, data, returnType) => {
    //const state = store.getState();
    //const token = state.token;
    try {
        const req = await fetch(URL + url, {
            method: "POST",
            headers: {
                "Authorization": "Bearer "
            },
            body: data
        });
        if(req.status !== 200){
            const response = await req.json();
            return {status: "error", data: response.message};
        }
        if (returnType) {
            const response =  await req.text();
            return {status: "success", data: response};
        }
        const response = await req.json();
        return response;
    } catch (e) {
        console.log(e);
    }
};

export default Request;
